define("discourse/plugins/swapd-trustap/discourse/components/trustap-connect-button", ["exports", "@glimmer/component", "discourse-common/helpers/d-icon", "@ember/object", "discourse/components/d-button", "@ember/service", "discourse-common/helpers/i18n", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dIcon, _object, _dButton, _service, _i18n, _ajax, _ajaxError, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TrustapConnectButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get trustapAccount() {
      return this.args.user.trustap_id || '-';
    }
    get showConnectButton() {
      // don't show if you're an admin looking at someone else
      if (this.args.user.id != this.currentUser.id) {
        return false;
      }
      let trustap_id1 = this.args.user.trustap_id;
      // do show if no id
      if (trustap_id1 === null || trustap_id1 === '') {
        return true;
      }
      // do show if guest
      return trustap_id1.startsWith('1-');
    }
    get trustapAccountTypeAsString() {
      let trustap_id1 = this.args.user.trustap_id || null;
      if (trustap_id1 === null || trustap_id1 === '') {
        return '';
      }
      if (trustap_id1.startsWith('1-')) {
        return '(Guest User)';
      } else {
        return '(Full User)';
      }
    }
    get showRemoveAccountButton() {
      return this.siteSettings.swapd_trustap_enable_test_functions && this.args.user.trustap_id;
    }
    startOpenID() {
      window.location.href = '/trustap/openid-start';
    }
    static #_3 = (() => dt7948.n(this.prototype, "startOpenID", [_object.action]))();
    async removeAccount() {
      let result1 = await (0, _ajax.ajax)(`/trustap/remove-account`, {
        type: "POST",
        data: {
          target_user_id: this.args.user.id
        }
      }).then(data1 => {
        alert("Account removed");
        window.location.reload();
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        //
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "removeAccount", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="control-group pref-trustap">
          <label class="control-label">TrustAP</label>
          Account ID: <code>{{this.trustapAccount}}</code> {{ this.trustapAccountTypeAsString }}
          {{#if this.showConnectButton}}
            <DButton
              @action={{this.startOpenID}}
              @label="trustap.connect_with_trustap"
            />
            <div class="instructions">{{i18n "trustap.convert_to_full_user"}}</div>
          {{/if}}
          {{#if this.showRemoveAccountButton}}
              <DButton
                @action={{this.removeAccount}}
                @label="trustap.remove_account_button"
              />
            {{/if}}
        </div>
      
    */
    {
      "id": "sWprmmdM",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"control-group pref-trustap\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"TrustAP\"],[13],[1,\"\\n      Account ID: \"],[10,\"code\"],[12],[1,[30,0,[\"trustapAccount\"]]],[13],[1,\" \"],[1,[30,0,[\"trustapAccountTypeAsString\"]]],[1,\"\\n\"],[41,[30,0,[\"showConnectButton\"]],[[[1,\"        \"],[8,[32,0],null,[[\"@action\",\"@label\"],[[30,0,[\"startOpenID\"]],\"trustap.connect_with_trustap\"]],null],[1,\"\\n        \"],[10,0],[14,0,\"instructions\"],[12],[1,[28,[32,1],[\"trustap.convert_to_full_user\"],null]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showRemoveAccountButton\"]],[[[1,\"          \"],[8,[32,0],null,[[\"@action\",\"@label\"],[[30,0,[\"removeAccount\"]],\"trustap.remove_account_button\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/swapd-trustap/discourse/components/trustap-connect-button.js",
      "scope": () => [_dButton.default, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TrustapConnectButton;
});