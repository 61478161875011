define("discourse/plugins/swapd-trustap/discourse/components/modal/trustap-ask-reason", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/components/d-button", "discourse/components/d-modal", "discourse/components/preference-checkbox", "discourse-i18n", "@ember/component", "discourse/components/d-textarea", "discourse-common/helpers/i18n", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _dButton, _dModal, _preferenceCheckbox, _discourseI18n, _component2, _dTextarea, _i18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TrustapAskReason extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "reasonText", [_tracking.tracked], function () {
      return '';
    }))();
    #reasonText = (() => (dt7948.i(this, "reasonText"), void 0))();
    constructor() {
      super(...arguments);
    }
    get modalTitle() {
      return this.args.model.title;
    }
    get modalText() {
      return this.args.model.text;
    }
    get modalButton() {
      return this.args.model.button;
    }
    get cancelButton() {
      return this.args.model.cancel_button;
    }
    get actionDisabled() {
      return this.reasonText.length < 5;
    }
    performAction() {
      this.args.model.actionCallback({
        reasonText: this.reasonText
      });
      this.args.closeModal();
    }
    static #_2 = (() => dt7948.n(this.prototype, "performAction", [_object.action]))();
    cancelAction() {
      this.args.closeModal();
    }
    static #_3 = (() => dt7948.n(this.prototype, "cancelAction", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @closeModal={{@closeModal}}
          @title={{this.modalTitle}}
          @inline={{@inline}}
        >
          <:body>
            <p>
              {{this.modalText}}
            </p>
              <Textarea
                id="trustap-ask-reason"
                @value={{this.reasonText}}
              />
          </:body>
          <:footer>
            <DButton
              @action={{this.performAction}}
              @icon="check"
              @label={{this.modalButton}}
              @disabled={{this.actionDisabled}}
              id="trustap-ask-reason-confirm"
              class="btn-primary"
            />
                    <DButton
              @action={{this.cancelAction}}
              @icon="arrow-left"
              @label={{this.cancelButton}}
              id="trustap-ask-reason-cancel"
            />
          </:footer>
        </DModal>
      
    */
    {
      "id": "ZBfznMJV",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@closeModal\",\"@title\",\"@inline\"],[[30,1],[30,0,[\"modalTitle\"]],[30,2]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,2],[12],[1,\"\\n          \"],[1,[30,0,[\"modalText\"]]],[1,\"\\n        \"],[13],[1,\"\\n          \"],[8,[32,1],[[24,1,\"trustap-ask-reason\"]],[[\"@value\"],[[30,0,[\"reasonText\"]]]],null],[1,\"      \"]],[]],[[[1,\"\\n        \"],[8,[32,2],[[24,1,\"trustap-ask-reason-confirm\"],[24,0,\"btn-primary\"]],[[\"@action\",\"@icon\",\"@label\",\"@disabled\"],[[30,0,[\"performAction\"]],\"check\",[30,0,[\"modalButton\"]],[30,0,[\"actionDisabled\"]]]],null],[1,\"\\n                \"],[8,[32,2],[[24,1,\"trustap-ask-reason-cancel\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"cancelAction\"]],\"arrow-left\",[30,0,[\"cancelButton\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@inline\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/swapd-trustap/discourse/components/modal/trustap-ask-reason.js",
      "scope": () => [_dModal.default, _dTextarea.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TrustapAskReason;
});